import React from 'react';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Databuilder - Coming Soon 👷</h1>
        <p>Building better places. 🏡</p>
      </header>
      <main className="App-main">
        <section className="service">
          <h2>Machine Learning Models & AI Tooling</h2>
          <p>Custom-built models designed to meet the unique needs of various sectors, enhancing efficiency and decision-making.</p>
        </section>
        <section className="service">
          <h2>Data & AI Strategy Consultancy</h2>
          <p>Providing strategic guidance to help organisations harness the power of data and AI, ensuring they stay ahead in a rapidly evolving landscape.</p>
        </section>
        <section className="service">
          <h2>Interim Chief Data Officer Services</h2>
          <p>Offering interim leadership to drive data initiatives, implement best practices, and build robust data strategies.</p>
        </section>
        <section className="service">
          <h2>Comprehensive Consultancy Services</h2>
          <p>From data governance to AI implementation, I provide end-to-end consultancy to help organisations achieve their data-driven goals.</p>
        </section>
        <section className="service">
          <h2>Data as a Service (DaaS)</h2>
          <p>Delivering scalable data solutions that allow organisations to access, manage, and analyse data without the need for extensive infrastructure.</p>
        </section>
        <section className="service">
          <h2>Prebuilt Models for Key Sectors</h2>
          <p>Offering ready-to-deploy models that address specific challenges in sectors such as Local Government, Emergency Services, Housing, and Insurance Services. These models are designed to streamline operations, improve service delivery, and enhance decision-making.</p>
        </section>
        <section className="service">
          <h2>Cost Savings</h2>
          <p>My work in local authorities has resulted in significant cost savings, including £100,000 in data processing. I am committed to helping clients achieve similar efficiencies and financial benefits.</p>
        </section>
        <section className="service">
          <h2>Thought Leadership</h2>
          <p>As a thought leader in the field of data and AI, I regularly share insights and trends through speaking engagements, publications, and industry forums. My goal is to inspire and educate others on the transformative potential of data and AI.</p>
        </section>
        <section className="service">
          <h2>AI and Workforce Optimisation</h2>
          <p>In the context of local government, I provide strategic guidance on using AI to optimise workforce efficiency. This includes managing retrenchment processes with sensitivity and care, ensuring that job reductions are handled responsibly while maintaining service quality and operational effectiveness.</p>
        </section>
        <section className="service">
          <h2>1 - 1 Executive Support</h2>
          <p>Offering personalised 1-1 support to board-level staff, helping executives navigate the complexities of data and AI strategy. This includes tailored advice, strategic planning, and hands-on assistance to ensure that leadership teams are well-equipped to drive their organisations forward.</p>
        </section>
      </main>
      <Footer />
    </div>
  );
}

function Footer() {
  return (
    <footer className="App-footer">
      <p>Databuilder Limited is registered in England and Wales under company number 15906801. Our registered office is 91 Parklands Avenue, Leamington Spa, England, CV32 7BP </p>
    </footer>
  );
}

export default App;